import {useEffect} from 'react';
import {useRouter} from 'next/router';

/* Client State */
import {useCouponsActions} from '@store/client/store.coupons';
import {useTrackingActions, useTrackingPhone} from '@store/client/store.tracking';

/* Utils */
import {EXPIRY_14_DAYS} from '@utils/cookies/cookies.constants';
import {getPhoneNumber, PHONE_TRACKING_TYPES, setPhoneNumber} from '@utils/cookies/phone';
import {setCouponIdCookie} from '@utils/cookies/coupon';
import {pushToDataLayer} from '@integrations/analytics/google.gtag';
import {getBrandingCookie} from '@utils/cookies/branding';
import {useUserHasSubscriptionSelector} from '@store/queries/user/useUser.selector';
import usePartnerCache from '@integrations/partners/usePartnerQuery.cache';
import useBrandingCache from '@integrations/partners/branding/useBrandingQuery.cache';
import {HT_INFO_PHONE} from '@constants/hellotech';
import {TPhone} from 'types/base.types';

type TPhoneGroup = {name: string | number | TPhone; cookieFn?: BaseAnyFunction};

/**
 * Set global cookies or state depending on the route params.
 *
 * NOTE: This is global concerns, so it should be in the app.globalsettings.ts file
 */

export const Phone = () => {
  /* Hooks */
  const router = useRouter();
  const {phone_number: phoneNumber, phone: queryPhone} = router.query;

  /* Client State & Actions */
  const clientStatePhone = useTrackingPhone();
  const {setPhoneState} = useTrackingActions();

  /* Cookie: User might not be logged in */
  const userPartnerSubscription = getBrandingCookie();

  /* Route Phones */
  const paramsPhone = phoneNumber || queryPhone;

  /* Hooks */
  const hasSubscription = useUserHasSubscriptionSelector();

  /* Do we have a partner phone (in the partner object) */
  const cachedPartner = usePartnerCache();
  const cachedBranding = useBrandingCache();

  /* Constants */
  const basePhone = HT_INFO_PHONE.customers.default;
  const subscriberPhone = HT_INFO_PHONE.customers.subscriptionHolder.default;

  /* Methods */
  /**
   * We have a precedence order, this is fed phone data and returns true if valid
   *
   * Note: Hoop jumping because phone can exist as a string|number|TPhone
   *
   * @param {string | number | TPhone} phoneValue
   * @returns {boolean}
   */
  const getPhoneValue = (entry: TPhoneGroup) => {
    const phoneValue = entry.name;

    // Check if phoneValue is valid based on type and structure
    if (!phoneValue) return false;

    const isValidPhone = typeof phoneValue === 'string' || typeof phoneValue === 'number' || (typeof phoneValue === 'object' && phoneValue.value);

    // If valid, set cookie if needed and return the phoneValue
    if (isValidPhone) {
      if (entry.cookieFn) entry.cookieFn(phoneValue); // Set cookie with the phone value if cookieFn exists
      return typeof phoneValue === 'object' ? phoneValue.value : phoneValue;
    }

    return false;
  };

  /**
   * Function wrapper to set the phone number in the cookie
   * @param cookieName
   * @returns {(value) => void}
   */
  const setCookiedPhone = (cookieName: string) => (value: string) => {
    setPhoneNumber(cookieName, value, EXPIRY_14_DAYS);
  };

  useEffect(() => {
    const cookiedPhone = Object.values(PHONE_TRACKING_TYPES).reduce((all, c) => {
      /* Iterate over possible cookied items - non branding/partner */
      const cookiePhone = getPhoneNumber(c);

      all[c] = {
        name: cookiePhone,
        cookieFn: setCookiedPhone(c),
      };

      return all;
    }, {});

    /**
     * ---------------------------------------------------
     * planAssociatedPhoneNumber - if we have a plan, use it
     * cookiedPhoneByPriority - previous adword, sub
     * subscriberPhone - if we have a subscription, use it
     * cachedPartner - if we have a partner, use it
     * phone - if we have a phone, usually from a query (adword phone etc)
     * basePhone - if we have nothing, use the default
     *
     * This is in a precedence order. I don't make the rules...
     */
    const phoneToUse = [
      {name: hasSubscription && subscriberPhone, cookieFn: setCookiedPhone(PHONE_TRACKING_TYPES.SUBSCRIPTION_PHONE)},
      {...cookiedPhone[PHONE_TRACKING_TYPES.SUBSCRIPTION_PHONE]},
      {name: userPartnerSubscription?.phone},
      {name: cachedPartner?.planAssociatedPhoneNumber},
      {name: cachedBranding?.planAssociatedPhoneNumber},
      {name: cachedPartner?.phone},
      {...cookiedPhone[PHONE_TRACKING_TYPES.LANDING_PHONE]},
      {...cookiedPhone[PHONE_TRACKING_TYPES.ADWORD_PHONE]},
      {name: paramsPhone, cookieFn: setCookiedPhone(PHONE_TRACKING_TYPES.ADWORD_PHONE)},
      {name: basePhone},
    ].find(getPhoneValue);

    setPhoneState(phoneToUse.name);
  }, [hasSubscription, subscriberPhone, cachedPartner, paramsPhone]);

  /**
   * When the phone changes, we should send to GA
   * 1. Send to datalayer
   */
  useEffect(() => {
    if (clientStatePhone?.title) {
      /* 1. */
      pushToDataLayer({phone_conversion_number: clientStatePhone.title});
    }
  }, [clientStatePhone]);

  return null;
};

/**
 * Handle App Level Coupon Logic in which user comes into site with coupon in url
 *
 * @returns {null}
 * @constructor
 */
export const Coupon = () => {
  /* Hooks */
  const router = useRouter();
  const {add_coupon_id: addCouponId} = router.query;

  /* Client State */
  const {setCoupons} = useCouponsActions();

  if (addCouponId) {
    /* set cookie for 1 hour. Also, enables us to pass this to client */
    setCouponIdCookie(addCouponId as string);
    /* set in state */
    setCoupons({param: addCouponId as string});
  }

  return null;
};
