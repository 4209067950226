/* Store */
import {userQueryKeys} from '@store/queries/user/queries.keys';
import {cartQueryKeys} from '@store/queries/cart/queries.keys';

/* Utils */
import Bugsnag from '@bugsnag/js';
import {sleep} from '@utils/helpers';

/* Integrations */
import Chat from '@integrations/gladly/Chat/Gladly.methods';
import {SegmentTrackingAction} from '@integrations/segment/user';
import {queryClient} from '@integrations/reactquery';

/* Types */
import {Cart} from 'types/cart.types';
import {TAuth} from 'types/base.types';
import {User} from 'types/user.types';

/**
 * This updates the global needs of the application, as it relates to state & third party integrations.
 *
 * @param {User} user
 * @param {Cart} cart
 * @param {TAuth} auth
 * @returns {Promise<void>}
 */
export const updateContingencies = async (user: User | null, {cart, auth}: {cart?: Cart | null; auth?: TAuth | null} = {}) => {
  await sleep(0);

  /* Update Global Concerns for the application. Auth, Cart, User */
  queryClient.setQueryData(userQueryKeys.all, (prevData = {}) => ({...(typeof prevData === 'object' && {...prevData}), ...user, pristine: false}));
  queryClient.setQueryData(userQueryKeys.auth, (prevData = {}) => {
    const baseObject = auth ? {...auth} : {...user};
    return {
      ...(typeof prevData === 'object' && {...prevData}),
      authToken: baseObject.authToken,
      formAuthenticityToken: baseObject.formAuthenticityToken,
    };
  });

  /* We will usually deal with a cart on a first load (session) */
  if (cart) {
    queryClient.setQueryData(cartQueryKeys.all, cart || {});
  }

  /* NOTE: Probably a good idea to move this out to a Component in AppLevelSideEffect for <User /> */
  if (user) {
    const {id, name, email} = user || {};

    /* Set Third Party Users */
    SegmentTrackingAction.userSignedUp(user);
    Chat.hideChat();
    Chat.identify({email: user.email!, name: user.name});
    Bugsnag?.setUser?.(id as string, email as string, name);
  }

  return Promise.resolve();
};
