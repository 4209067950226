import {HT_INFO_PHONE} from '@constants/hellotech';
import {TPhone} from 'types/base.types';

export const displayPhone = (phone: string) => {
  if (phone === HT_INFO_PHONE.customers.empty.title) return null;
  return phone === '1---' ? HT_INFO_PHONE.customers.default.title : phone;
};

/**
 * Takes a number and returns a rounded value, to ten.
 *
 * @param value
 * @return number
 */
export const roundToNearestTen = (value: number) => Math.ceil(value / 10) * 10;

/**
 * Takes a number, and comma seperates it.
 * @param number
 * @return string
 */
export const toCommaSeparated = (number: number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 *  Format a string representation of a phone number with dashes only
 *
 *  '8006409005' -> 1-800-640-9005
 */
const phoneRegexpFormatted = /^(1|)-?(\d{3})-(\d{3})-(\d{4})$/;
const phoneRegexp = /^(1|)?(\d{3})(\d{3})(\d{4})$/;

export const checkFormattedPhone = (phone: string) => phoneRegexpFormatted.test(phone);

export const formatPhoneDash = (phone: string | TPhone = '') => {
  if (!phone) return '';

  if (typeof phone === 'string') {
    const stripped = phone.replace(/\D/g, '');
    const regexMatch = stripped.match(phoneRegexp);
    if (regexMatch) {
      const [, intlCode, areaCode, number1, number2] = regexMatch;
      return `${intlCode || '1'}-${areaCode}-${number1}-${number2}`;
    }

    return phone;
  }

  return phone.title;
};

/**
 *  Format a string representation of a phone number for use in anchor's hyperlink
 *
 *  '800-640-9005' -> 'tel:18006409005'
 */
export const formatPhoneHref = (phone: string | TPhone = '') => {
  if (!phone) return '';

  if (typeof phone === 'string') {
    const stripped = phone.replace(/\D/g, '');
    const regexMatch = stripped.match(phoneRegexp);
    if (regexMatch) {
      const [, intlCode, areaCode, number1, number2] = regexMatch;
      return `tel:${intlCode || '1'}${areaCode}${number1}${number2}`;
    }

    return `tel:${phone}`;
  }

  return phone.link;
};

// E.g. formatter(0).format(49.00)
export const currencyFormatter = (digits = 0) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
  });

/**
 * Format a string representation of a phone number for use in anchor's hyperlink and display
 * @param phone
 * @returns {
 *   {displayPhone: (phone: string) => (null | string),
 *   formatPhoneHref: (phone?: string) => string,
 *   formatPhoneDash: (phone?: string) => (string | string)}}
 */

export const getPhoneObject = (phone: string | TPhone): TPhone => {
  // Validate if it's already a phone object
  if (typeof phone === 'object' && phone !== null) {
    if ('value' in phone && 'title' in phone && 'link' in phone) {
      return phone;
    }
  }

  // Handle string case
  if (typeof phone === 'string') {
    /* Sanitize - remove non-digits */
    const p = phone.replace(/\D/g, '');

    if (p.length === 11) {
      return {
        value: p,
        title: formatPhoneDash(p),
        link: formatPhoneHref(p),
      };
    }

    if (p.length === 10) {
      // Add country code for 10-digit numbers
      const fullNumber = `1${p}`;
      return {
        value: fullNumber,
        title: formatPhoneDash(fullNumber),
        link: formatPhoneHref(fullNumber),
      };
    }
  }

  /* Invalid phone or unsupported format */
  return {
    value: '',
    title: '',
    link: '',
  };
};

/* Helper replace spaces with plus(or passed arg). Defaults to "+" */
export const replaceSpaces = (str: string = '', replaceWith = '+') => str?.trim().replace(/\s/g, replaceWith);
