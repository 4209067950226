import { useEffect } from 'react';
import { logWarning } from '@integrations/bugsnag';
import { queryClient } from '@integrations/reactquery';
import { userQueryKeys } from '@store/queries/user/queries.keys';
import { sleep } from '@utils/helpers';
import { cartQueryKeys } from '@store/queries/cart/queries.keys';
import { updateContingencies } from '@store/queries/user/user.utils';
import { setUserPlanBrandingAndCookies } from '@integrations/partners/branding/branding.utils';
import { useResponsiveActions } from '@store/client/store.responsive';


const Session = () => {
  const {setInitializeResponsive} = useResponsiveActions();

  useEffect(() => {
      (async () => {
        setInitializeResponsive();
        /*
          To allow for perf gains with ssg (we don't use ssr here) so get session when user hits.
          This is a modified session for speed. We'll revisit this when we get into /cart
        */
        const data = {user: {}, cart: {}, auth: {}};
        let user;
        let cart;
        let auth;

        try {
          const response = await fetch('/api/_nextassets_/session');
          const jsonData = await response.json();
          user = jsonData.user;
          cart = jsonData.cart;
          auth = jsonData.auth;
        } catch (e) {
          let err = e as Error;
          if (!navigator.onLine) {
            err = new Error('Failed to fetch: Offline');
          }
          if (Object.keys(err).length > 0) {
            logWarning(err);
          }
        }

        queryClient.setQueryData(userQueryKeys.auth, auth || data.auth);
        await sleep(0);
        queryClient.setQueryData(userQueryKeys.auth, auth || data.auth);
        queryClient.setQueryData(cartQueryKeys.all, cart || data.cart);
        queryClient.setQueryData(userQueryKeys.all, user || data.user);

        /*
           This updates the user, auth & cart.
           It also updates third party concerns ie. Gladly, Bugsnag, Segment
        */
        await updateContingencies(user, {auth, cart});

        const userPartner = user?.account?.subscription?.partner;
        /*
          1. Does our user have a subscription with a partner?
          2. If so, lets set the partner in the queryClient & in _ht_branding cookie
        */
        if (userPartner) {
          setUserPlanBrandingAndCookies(user);
        }
      })();
  }, [])

  return null;
}

export default Session;
