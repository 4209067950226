/*
 * APP LEVEL STATE
 *
 * These are base state items that do not qualify for their own atomic state
 * ie. loaders, third party
 *
 * FEATURE STATE SHOULD EXIST IN THE FEATURE PROPER, NOT IN THE 'STORE' DIRECTLY. CO-LOCATE WITH YOUR FEATURE>
 * */
import {createWithEqualityFn} from 'zustand/traditional';
import {immer} from 'zustand/middleware/immer';

/* TYPES */
export type AppState = {
  appLoading: boolean[];
  appLevelLoading: boolean;
  splitInBadState: boolean;
  openSlideModalLogin: boolean;
  searchBarIsOpen: boolean;

  actions: {
    setAppLevelLoading: (loading: boolean) => void;
    setSplitAppLevel: (splitInBadState: boolean) => void;
    setOpenSlideModalLogin: (open: boolean) => void;
    setSearchBarIsOpen: (open: boolean) => void;
  };
};

/* ----------------------------------------------------------------------- */
/* ---------------------  GLOBAL STORE SETUP  ---------------------------- */
/* ----------------------------------------------------------------------- */

const useAppStore = createWithEqualityFn<AppState>()(
  immer((set, get) => ({
    appLoading: [],
    appLevelLoading: false,
    splitInBadState: false,
    openSlideModalLogin: false,
    searchBarIsOpen: false,

    /* ------- Actions ---------- */
    /*
       Async actions can cause incorrect loading states. Two calls loading, true, one returns first. loading is now false.
       but, it should still be true, as one api is still outstanding. This fixes it.
    */
    actions: {
      setAppLevelLoading: loading => {
        const passedLoading = typeof loading === 'boolean' ? loading : false;
        const currentLoaders = get().appLoading;
        const findTrue = currentLoaders.lastIndexOf(true);
        const balancedAppLoader = loading === false ? currentLoaders.splice(findTrue, 1) : currentLoaders;

        if (passedLoading === false) {
          set(state => {
            state.appLevelLoading = balancedAppLoader.some(Boolean);
            state.appLoading = balancedAppLoader;
          });
        }

        if (passedLoading === true) {
          set(state => {
            state.appLevelLoading = true;
            state.appLoading = balancedAppLoader.concat(passedLoading);
          });
        }
      },
      setSplitAppLevel: stateOfSplit =>
        set(state => {
          state.splitInBadState = stateOfSplit;
        }),
      setOpenSlideModalLogin: stateOfSlideModal =>
        set(state => {
          state.openSlideModalLogin = stateOfSlideModal;
        }),
      setSearchBarIsOpen: stateOfSearch => {
        set(state => {
          state.searchBarIsOpen = stateOfSearch;
        });
      },
    },
  })),
);

/* Export Selectors */
export const useAppStoreActions = () => useAppStore(state => state.actions);
export const useAppOpenSlideModalLogin = () => useAppStore(state => state.openSlideModalLogin);
export const useAppSearchBarIsOpen = () => useAppStore(state => state.searchBarIsOpen);
export const useAppSplitIsInBadState = () => useAppStore(state => state.splitInBadState);

export default useAppStore;
